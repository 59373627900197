import React from "react"
import Layout from "@/components/layout"
import Portfolio from "@/components/portfolio"

export default function Header() {
  return (
    <Layout currentPage="index">
      <Portfolio />
    </Layout>
  )
}
